import { Component, Inject, LOCALE_ID, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { EventActivity, Schedule, Location, Error} from '../../models';
import * as fromStore from '../../store';
import * as fromRoot from '../../../store';

import { SubjectComponent } from '../../components';
import { CloseDialogAction } from '../../../models';
import { ModalService } from '../../services/modal.service';
import { Alert, AlertClass } from '../../models/alert.model';

@Component({
  templateUrl: './event-activity.component.html',
  styleUrls: ['./event-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActivityComponent implements OnInit {
  eventActivityEntity$: Observable<EventActivity>;
  eventActivitySubject$: Observable<string>;
  dialogReady$: Observable<boolean>;
  loading$: Observable<boolean>;
  eventActivitySubjectIsValid$: Observable<boolean>;
  isSavingEnabled$: Observable<boolean>;
  newActivity$: Observable<boolean>;
  eventName$: Observable<string>;
  notes$: Observable<string>;
  notFoundErrorCode$: Observable<boolean>;
  language$: Observable<string>;
  eventSchedules$: Observable<Schedule[]>;
  selectedEventSchedulePlaceId$: Observable<string>;
  selectedEventLocation$: Observable<Location>;
  hasAnyADFs$: Observable<boolean>;
  summaryAlert$: Observable<Alert[]>;
  eventId$: Observable<string>;
  dirty$: Observable<boolean>;
  isSymbolEnabled$: Observable<boolean>;
  isAttachmentEnabled$: Observable<boolean>;
  isHubActivityFormEnabled$: Observable<boolean>;
  isVirtualEvent$: Observable<boolean>;

  @ViewChild(SubjectComponent, {static: false}) subject: SubjectComponent;

  constructor(
    private store: Store<fromStore.State>,
    @Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    private modalService: ModalService,
    private translateService: TranslateService
  ) {
    this.store
      .pipe(
        select(fromRoot.getCulture),
        filter(d => !!d),
        take(1)
      )
      .subscribe(locale => {
        this.localeId = locale;
        (<CldrIntlService>this.intlService).localeId = locale;
      });
  }

  ngOnInit(): void {
    this.store.dispatch(new fromStore.Init());

    this.eventActivityEntity$ = this.store.pipe(select(fromStore.getEventActivityEntity));
    this.eventActivitySubject$ = this.store.pipe(select(fromStore.getEventActivitySubject));
    this.dialogReady$ = this.store.pipe(select(fromStore.getDialogReady));
    this.loading$ = this.store.pipe(select(fromStore.getEventActivityLoading));
    this.eventActivitySubjectIsValid$ = this.store.pipe(
      select(fromStore.getEventActivitySubjectIsValid)
    );
    this.isSavingEnabled$ = this.store.pipe(select(fromStore.getIsSavingEnabled));
    this.newActivity$ = this.store.pipe(select(fromStore.getIsNewActivity));
    this.isVirtualEvent$ = this.store.pipe(select(fromStore.getIsVirtualEvent));
    this.eventName$ = this.store.pipe(select(fromStore.getEventName));
    this.eventSchedules$ = this.store.pipe(select(fromStore.getUniqueByPlaceSchedules));
    this.selectedEventSchedulePlaceId$ = this.store.pipe(select(fromStore.getEventLocationPlaceId));
    this.selectedEventLocation$ = this.store.pipe(select(fromStore.getEventLocation));
    this.eventId$ = this.store.pipe(select(fromStore.getEventId));
    this.hasAnyADFs$ = this.store.pipe(select(fromStore.getHasAnyActivityDefinedFields));
    this.notes$ = this.store.pipe(select(fromStore.getNotes));
    this.language$ = this.store.pipe(select(fromRoot.getLanguage));
    this.summaryAlert$ = this.buildAlertsObservable();
    this.notFoundErrorCode$ = this.store.pipe(
        select(fromStore.getEventActivityError),
        map(d => d && d === Error.NotFound)
    );
    this.dirty$ = this.store.pipe(select(fromStore.getFormDirty));
    this.isSymbolEnabled$ = this.store.pipe(select(fromRoot.getIsSymbolEnabled));
    this.isAttachmentEnabled$ = this.store.pipe(select(fromRoot.getIsAttachmentEnabled));
    this.isHubActivityFormEnabled$ = this.store.pipe(select(fromRoot.getIsHubActivityFormEnabled));
  }

  private buildAlertsObservable() {
    const partialSaveAlert$: Observable<Alert> = this.store.pipe(
      select(fromStore.getEventActivityError),
      map(error => !!error && error === Error.PartialSaveError ? {
          alertClass: AlertClass.Warning,
          message: this.translateService.instant('bd/event-activity/form.activity_partial_saving_error_message')
        } : null
      ));

    const errorAlert$: Observable<Alert> = this.store.pipe(
      select(fromStore.getEventActivityError),
      map(error => !!error && error !== Error.None && error !== Error.PartialSaveError ? {
          alertClass: AlertClass.Error,
          message: this.translateService.instant('bd/event-activity/form.activity_saving_error_message')
        } : null
      ));

    const maxSafeParticipantCount = 300;
    const warningAlert$: Observable<Alert> = this.store.pipe(
      select(fromStore.getNewExternalParticipantsCount),
      map(count => count > maxSafeParticipantCount ? {
        alertClass: AlertClass.Warning,
        message: this.translateService.instant(
          'bd/event-activity/form.activity_participants_saving_warning_message', { count: maxSafeParticipantCount })
      } : null)
    );

    return combineLatest([partialSaveAlert$, errorAlert$, warningAlert$]).pipe(map(alerts => alerts.filter(a => !!a)));
  }

  onSubjectChange(newValue: string) {
    this.store.dispatch(new fromStore.UpdateSubject(newValue));
  }

  onEventLocationSelected(payload: { scheduleId: number; timeZone: string }) {
    this.store.dispatch(new fromStore.UpdateEventLocation(payload));
  }

  onNotesChange(notes: string) {
    this.store.dispatch(new fromStore.UpdateNotes(notes));
  }

  close() {
    this.store.dispatch(new fromRoot.CloseDialog(CloseDialogAction.Refresh));
  }

  cancel() {
    this.dirty$
    .pipe(take(1))
    .subscribe(dirty => {
      if (dirty) {
        this.modalService.openModal({
          title: 'bd/event-activity/form.close_without_save_title',
          text: 'bd/event-activity/form.close_without_save_warning',
          titleCancelButton: 'bd/event-activity/form.go_back',
          titleConfirmButton: 'bd/event-activity/form.close',
          type: 'info',
          confirmEventEmit: () => this.store.dispatch(new fromStore.CancelForm())});
      } else {
        this.store.dispatch(new fromStore.CancelForm());
      }
    });
  }

  saveAndClose() {
    this.store.dispatch(new fromStore.SubmitForm());
  }

  delete() {
      this.store.dispatch(new fromStore.DeleteActivity());
  }

  onCanceledSave() {
    this.subject.focus();
  }
}
